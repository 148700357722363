import { Delete } from "@mui/icons-material";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import React from "react";
import { useSelector } from "react-redux";
import { selectStudiesByCodes } from "../state/selectors/catalogs";

const StudyFinder = ({studyCodes, catalog, add, remove, disabled}) => {

    const handleSelect = (event, values) => {
        if (values){       
            add(values.code);
        }    
    }

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 50,
            sortable: false,
            hide:true,
            type: 'number'
        },
        {
            field: 'code',
            headerName: 'Codigo',
            sortable: false,
            type: 'string',
            flex: 0.5,
            minWidth: 35,
        },
        {
            field: 'name',
            headerName: 'Nombre',
            sortable: false,
            flex: 1,
            minWidth: 150
        },
        {
            field: 'action_remove',
            headerName: 'Quitar',
            sortable: false,
            minWidth: 10,
            renderCell: (params) => !disabled && <IconButton onClick={()=>remove(params.row)}><Delete/></IconButton>

        }
    ];

    const studies = useSelector(selectStudiesByCodes(studyCodes || []));

    const data = {rows:studies,columns:columns};

    return(
        <div className="column-container margin-top all-height">
            <Autocomplete 
                style={{marginBottom:'1em'}}
                id="study_search" 
                name="study_search" 
                size="small"
                disabled={disabled}
                loading={false}
                options={catalog || []}
                autoComplete
                includeInputInList
                getOptionLabel={(option) => `${option.code} ${option.name}`}
                onChange={(event, values) => handleSelect(event, values)}
                renderInput={(params) => (<TextField {...params} id="studyType" name="studyType" label="Buscar estudio" variant="outlined"/>)}
            />
            <div className="grow margin-top">
                <DataGridPro
                    {...data}
                    headerHeight={60}
                    rowHeight={40}
                    disableMultipleSelection
                    disableColumnMenu
                    disableColumnSelector
                    disableMultipleColumnsSorting
                    hideFooter
                    onCellClick={(params) => handleSelect(params.row)}
                />
            </div>
        </div>)
};

export default StudyFinder;

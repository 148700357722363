import { actionTypes } from '../actions/liquidation';
import HttpService from "../../services/HttpService";

const receiveLiquidations = (liquidations) => ({type:actionTypes.receiveLiquidations, liquidations});

export const setParams = (params) => ({type:actionTypes.setParams, params});

export const fetchLiquidations = (params) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchLiquidations });
        dispatch({ type: actionTypes.setParams, params });

        return HttpService.getInstance().getData(`billing/liquidations`, 'user-token', params)
            .then(response => {
                dispatch(receiveLiquidations(response.data));
            })
            .catch(error => {
                dispatch({type:actionTypes.clearLiquidations});
            })
    }
}


export const fetchProductionReport = (liquidation) => {
    return (dispatch) => {

        return HttpService.getInstance().getPDF(`billing/download/production/${liquidation.bill_id}`, 'user-token')
            .then((response) => {

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Reporte detallado liquidacion ${liquidation.id} - ${liquidation.customer.code} ${liquidation.customer.name}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch((err)=>{
                console.log(err);
                alert('No se pudo descargar el informe.')
            })
    }
}


export const fetchBillReport = (liquidation) => {
    return (dispatch) => {

        return HttpService.getInstance().getPDF(`billing/download/${liquidation.bill_id}`, 'user-token')
            .then((response) => {

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Factura ${liquidation.bill.number} - ${liquidation.customer.code} ${liquidation.customer.name}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch((err)=>{
                alert('No se pudo descargar el informe.')
            })
    }
}

export const actionTypes = {
    fetchBatch: 'FETCH_BATCH',
    receiveBatch: 'RECEIVE_BATCH',
    clearBatch : 'CLEAR_BATCH',
    creatingBatch:'CREATING_BATCH',
    createdBatch:'CREATED_BATCH',
    updateBatch:'UPDATE_BATCH'

};

export const clearBatch = { type: actionTypes.clearBatch };
export const updateBatch = { type: actionTypes.updateBatch };


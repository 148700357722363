import React, { useEffect, useState } from "react";

import { Box, Button, IconButton, LinearProgress, MenuItem, TextField, Typography } from "@mui/material";
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import BatchCard from "../components/BatchCard";
import { format, parseISO } from 'date-fns';
import '../styles/Views.css';
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Assessment,  Refresh,  Visibility } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {filterBatches, selectBatches} from "./../state/selectors/batches";
import {  fetchBatches, fetchBatchesPage, fetchCustomerBatches, fetchCustomerBatchesPage } from "../state/handlers/batches";
import { clearBatches } from "../state/actions/batches";
import { Link, useNavigate } from "react-router-dom";

import { createBatch } from "../state/handlers/batch";
import { selectBatch } from "../state/selectors/batch";
import { clearBatch } from "../state/actions/batch";
import { batchStatusList, progress, translate } from "../helpers/batchStatus";
import { selectProfile } from "../state/selectors/profile";

const VIEW_TYPE_LIST = "LIST";
const VIEW_TYPE_CARDS = "CARDS";

const CardsView = ({batches}) => {
    return (
        <div className="row-container flex-wrap">
            {
                batches?.map(
                    batch =>
                    <div className="flex-50" key={batch?.id}>
                        <BatchCard batch={batch}/>
                    </div>
                )
            }            
        </div>
    )
}

const ListView = ({batches}) => {

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            minWidth: 50,
            sortable: false,
            type: 'string'
        },
        {
            field: 'customer_name',
            headerName: 'Cliente',
            minWidth: 250,
            sortable: false,
            type: 'string',
            valueGetter: (params) => params.row.customer?.name 
        },
        {
            field: 'date',
            headerName: 'Fecha',
            sortable: false,
            type: 'date',
            minWidth: 50,
            valueGetter: (params) => format(parseISO(params.value), 'dd/MM/yyyy')
        },
        {
            field: 'status',
            headerName: 'Estado',
            sortable: false,
            minWidth: 120,
            valueGetter: (params) => translate(params.value)
        },
        {
            field: 'progress',
            headerName: 'Progreso',
            sortable: false,
            flex:1,
            renderCell: (params) => <LinearProgress color="primary" sx={{width:'100%', height:'1em'}} variant="determinate" value={progress(params.row.status)}></LinearProgress>,
            minWidth: 80
        },
        {
            field: 'view_batch',
            headerName: '',
            renderCell: (params) => <Link to={`/batch/${params.row.id}`} style={{textDecoration:'none'}}><Button color="secondary" startIcon={<Visibility />}>VER / EDITAR</Button></Link>,
            type: 'actions',
            minWidth: 180
        },
        {
            field: 'view_results',
            headerName: '',
            renderCell: (params) => <Link to={`/results?batch=${params.row.id}`} style={{textDecoration:'none'}}><Button  sx={{color:"black"}} startIcon={<Assessment/>}><Typography variant="body2"><Box fontWeight={550}>RESULTADOS</Box></Typography></Button></Link>,
            type: 'actions',
            minWidth: 180
        },

    ];

    const data = {rows:batches, columns:columns};

    return(
        <DataGridPro
            {...data}
            headerHeight={60}
            rowHeight={70}
            disableMultipleSelection
            disableColumnMenu
            disableColumnSelector
            disableMultipleColumnsSorting
            hideFooter
        />
    )
}

const Dashboard = () => {

const [viewType,setViewType] = useState(VIEW_TYPE_CARDS);
const [filter, setFilter] = useState('');
const [statusfilter, setStatusFilter] = useState('all');

const {data,created, creating} = useSelector(selectBatch);
const {loading, data:batches} = useSelector(filterBatches(filter));
const profile = useSelector(selectProfile);

const isCustomer = profile.data?.role.code === 'customer';
const customerId = profile.data?.customer?.id;

const navigate = useNavigate();
const dispatch = useDispatch();

useEffect(() => {
    handleRefresh('all');
    return () => {
        dispatch(clearBatch);
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

useEffect(() => {
    created && navigate(`/batch/${data.id}`);
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [created])


const handlePage = (page) => {
    if(isCustomer){
        dispatch(fetchCustomerBatchesPage(customerId,statusfilter,page))
    }
    else{
        dispatch(fetchBatchesPage(statusfilter,page))
    }
}

const handleRefresh = (status) => {
    if(isCustomer){
        dispatch(fetchCustomerBatches(customerId,status))
    }
    else{
        dispatch(fetchBatches(status))
    }
}

const handleChangeStatusFilter = (value) => {
    setStatusFilter(value);
    handleRefresh(value);
}

const handleNew = () => {

    if(batches?.data?.some(b=>b.status === 'new'))
    {
        alert(`Ya existe un lote en estado Nuevo.\nVerifique el lote #${batches?.data?.filter(b=>b.status === 'new')[0].id} por favor.`);
        return;
    }

    dispatch(createBatch(profile.data.customer.id));
}

const handleFirstPage = () => {
    handlePage(1);
}

const handlePreviousPage = () => {
    handlePage(batches?.current_page-1);
}

const handleNextPage = () => {
    handlePage(batches?.current_page+1);
}

const handleLastPage = () => {
    handlePage(batches?.last_page);        
}

const isFirst = batches?.current_page === 1;
const isLast = batches?.current_page === batches?.last_page;

return ( 
    <div className="column-container all-height">
        <div className="header row-container align-center">
            <div>
                <Typography variant="h5" color="text.black">
                    <Box fontWeight={750}>Mis Lotes</Box>
                </Typography>
            </div>
            <div className="grow"></div>
            <div className="grow margin">
                <TextField 
                    label="Filtrar lotes por Numero o Cliente." 
                    variant="standard" 
                    fullWidth
                    size="small"
                    value={filter}
                    onChange={(event)=>setFilter(event.target.value)}
                />
                
            </div>
            <div className="grow margin">
                <TextField 
                    label="Filtrar por estado" 
                    variant="standard" 
                    size="small"
                    select
                    fullWidth
                    value={statusfilter}
                    xs={{minWidth:'20em'}}
                    onChange={(event)=>handleChangeStatusFilter(event.target.value)}
                >
                    {
                        batchStatusList.map(
                            status => <MenuItem key={status.value} value={status.value}>{status.translate}</MenuItem>
                        )

                    }
                </TextField>
                
            </div>
            <div>
                <Button 
                    variant="contained"
                    color="secondary"
                    disabled={!profile.data.customer}
                    sx={{height:'2.5em', textTransform:'none', paddingLeft:'2em', paddingRight:'2em'}}
                    onClick={()=>handleNew()}>
                        <Typography>
                            Nuevo lote
                        </Typography>
                </Button>
                <Button 
                    variant="contained"
                    color="secondary"
                    sx={{marginLeft:'0.5em', height:'2.5em', textTransform:'none', paddingLeft:'2em', paddingRight:'2em'}}
                    onClick={()=>handleRefresh(statusfilter)}
                >
                    <Refresh/>
                </Button>
            </div>

            {
                creating && <LinearProgress/>
            }

        </div>
        <div className="progress-container margin-bottom">{(creating || loading) && <LinearProgress></LinearProgress>}</div>
        <div className="column-container body" style={{height:'100%'}}>
            <div className="row-container justify-right" style={{marginBottom:'1em'}}>

                <div className="row-container grow">
                    <div className="row-container" >
                        <Button disabled={isFirst} variant="outlined" onClick={handleFirstPage}>{"<<"}</Button>
                        <Button disabled={isFirst} variant="outlined" onClick={handlePreviousPage}>{"<"}</Button>
                        <Button disabled={isLast} variant="outlined" onClick={handleNextPage}>{">"}</Button>
                        <Button disabled={isLast} variant="outlined" onClick={handleLastPage}>{">>"}</Button>
                    </div>

                    <div className="row-container grow" style={{justifyContent:'center', alignItems:'center'}} >
                        <Typography sx={{marginRight:'1em'}}>{`Registros totales ${batches?.total || '-'}`}</Typography>
                        <Typography>{batches?.total > 0 && `Página ${batches?.current_page || '-'}  / ${batches?.last_page || '-'}`}</Typography>
                    </div>
                </div>

                <IconButton onClick={()=>setViewType(VIEW_TYPE_LIST)}>
                    <FormatListBulletedIcon/>
                </IconButton>
                <IconButton onClick={()=>setViewType(VIEW_TYPE_CARDS)}>
                    <ViewModuleIcon/>
                </IconButton>
            </div>

            <div className="grow" style={{overflowY:'auto', maxHeight:'70vh'}}>
                {   
                    viewType === VIEW_TYPE_CARDS && batches &&
                        <CardsView batches={batches?.data || []}/>
                }

                {   
                    viewType === VIEW_TYPE_LIST && batches &&
                        <ListView batches={batches?.data || []}/>
                }
            </div>

        </div>
    </div>
    );
};

export default Dashboard;

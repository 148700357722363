import { createSelector } from "reselect";

export const selectBatches = (state) => {
    return state.batches;
}

export const selectBatchesData = (state) => {
    return state.batches.data;
}

export const filterBatches = (filter) => {
    return createSelector(
        selectBatches,
        batches => filter === "" ? 
        batches :
        {
            ...batches,
            data: {
                ...batches.data,
                data:[
                    ...batches.data?.data?.filter(
                        batch => String(batch.id).includes(filter) || 
                            batch.status?.includes(filter) || 
                            batch.date?.includes(filter) || 
                            batch.customer?.name?.includes(filter) ||
                            batch.customer?.code?.includes(filter)
                        )
                ]
            }
        } 
    )
}
export const isLoadingBatches = (state) => {
    return state.batches.loading;
}
import { createSelector } from "reselect";

export const selectCustomersCatalog = (state) => {
    return state.catalogs.customers;
}

export const selectCustomersSorted = (state) => {
    return state.catalogs.customers.list.sort(
        function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          }
    );
}


export const selectCustomers = (state) => {
    return state.catalogs.customers.list;
}

export const searchCustomers = (filter) => {
    return createSelector(
        selectCustomers,
        customers => filter === "" ? customers : customers.filter(customer => 
            String(customer.code).toLowerCase().includes(filter.toLowerCase()) || 
            customer.name.toLowerCase().includes(filter.toLowerCase()) ||
            String(customer.tax_identification).toLowerCase().includes(filter.toLowerCase()) ||
            String(customer.company_name).toLowerCase().includes(filter.toLowerCase())
    ))
};


import { createSelector } from "reselect";

export const selectNextCustomer = (state) => {
    const customers = state.productionImport.customers;
    return customers.find(c=>c.status === 'pending');
}

export const selectProduction = (state) => {
    return state.production;
}

export const filterProduction = (filter) => {
    return createSelector(
        selectProduction,
        production => filter === "" ? 
        production : 
            {
                ...production, 
                data: {
                    ...production.data,
                    data: [
                            ...production.data.data.filter(item => 
                                String(item.customer.code).toLowerCase().includes(filter.toLowerCase()) || 
                                String(item.customer.name).toLowerCase().includes(filter.toLowerCase()) || 
                                String(item.efector.code).toLowerCase().includes(filter.toLowerCase()) || 
                                String(item.efector.name).toLowerCase().includes(filter.toLowerCase()) || 
                                String(item.study_type.code).toLowerCase().includes(filter.toLowerCase()) || 
                                String(item.study_type.name).toLowerCase().includes(filter.toLowerCase()) ||
                                String(item.locator).toLowerCase().includes(filter.toLowerCase())
                            )
                    ]
                }
            }
    )
}

export const selectImportData = (state) => {
    return state.productionImport;
}


export const selectLiquidationData = (state) => {
    return state.productionLiquidate;
}

export const selectNextCustomerToLiquidate = (state) => {
    const customers = state.productionLiquidate.customers;
    return customers.find(c=>c.status === 'pending');
}

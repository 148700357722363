import { createSelector } from "reselect";

export const selectLiquidations = (state) => {
    return state.liquidations;
}

export const filterLiquidations = (filter) => {
    return createSelector(
        selectLiquidations,
        liquidations => filter === "" ? 
        liquidations : 
            {
                ...liquidations, 
                list:
                    [
                        ...liquidations?.list?.filter(item => 
                            String(item.customer?.code).toLowerCase().includes(filter.toLowerCase()) || 
                            String(item.customer?.name).toLowerCase().includes(filter.toLowerCase()) || 
                            String(item.bill?.number).toLowerCase().includes(filter.toLowerCase())
                        )
                    ]
            }
    )
}

import React, { useEffect, useState } from 'react';
import { Box, Button, LinearProgress, TextField, Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';
import { selectRoutines, selectStudies, selectVisibleStudies } from '../state/selectors/catalogs';
import { addRoutineStudy, createRoutine, fetchRoutines, removeRoutine, removeRoutineStudy, setCurrentRoutine, updateRoutine } from '../state/handlers/routines';
import StudyFinder from '../components/StudyFinder';
import ConfirmationDialog from '../components/ConfirmationDialog';

const CRUD_IDLE = 'CRUD_IDLE'
const CRUD_NEW = 'CRUD_NEW';
const CRUD_EDITED = 'CRUD_EDITED';
const CRUD_SELECTED = 'CRUD_SELECTED';

const Routine = () => {


    const [routine, setRoutine] = useState({});
    const [crudMode,setCrudMode] = useState(CRUD_IDLE);

    const [removeRoutineDialog, setRemoveRoutineDialog] = useState({});
    const [removeRoutineStudyDialog, setRemoveRoutineStudyDialog] = useState({});

    const dispatch = useDispatch();

    const routineColumns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 50,
            sortable: false,
            hide:true
            
        },
        {
            field: 'code',
            headerName: 'Codigo',
            sortable: false,
            type: 'string',
            flex: 0.5,
            minWidth: 85,
        },
        {
            field: 'name',
            headerName: 'Nombre',
            sortable: false,
            flex: 0.5,
            minWidth: 110
        }
    ];

    const studies = useSelector(selectVisibleStudies);
    const {loading, list, current} = useSelector(selectRoutines);

    const routineData = {rows:list,columns:routineColumns};

    const handleSelect = (routine) => {
        setCrudMode(CRUD_SELECTED);
        setRoutine(routine);
        dispatch(setCurrentRoutine(routine));
    } 

    const handleChange = (event) => {
        if (crudMode === CRUD_SELECTED){
            setCrudMode(CRUD_EDITED);
        }

        setRoutine({...routine, [event.target.name]:event.target.value});
    }

    const handleNew = () => {
        setCrudMode(CRUD_NEW);
        setRoutine({});
        dispatch(setCurrentRoutine({}));
    };

    const handleSave = () => {
        
        if(crudMode===CRUD_NEW){
            dispatch(createRoutine(routine));
        };

        if(crudMode===CRUD_EDITED){
            dispatch(updateRoutine(routine));
        };

        setCrudMode(CRUD_IDLE);
    };

    const handleCancel = () => {
        
        if (current?.id > 0){
            setCrudMode(CRUD_SELECTED);
        }
        else{
            setCrudMode(CRUD_IDLE);
        }
        
        setRoutine(current);
        
    };

    const handleRemove = (routineId) => {
        dispatch(removeRoutine(routineId));
        dispatch(fetchRoutines());

        setCrudMode(CRUD_IDLE);
        setRoutine({});

        setRemoveRoutineDialog({});
    };


    const handleAddStudy = (study) => {
        dispatch(addRoutineStudy(routine.id, study));
    };

    const handleRemoveStudy = (routineId,studyCode) => {
        dispatch(removeRoutineStudy(routineId, studyCode));
        setRemoveRoutineStudyDialog({});
    };

    useEffect(()=>{
        if (crudMode===CRUD_IDLE && current !== routine){
            setRoutine(current);
        }
    },[crudMode,current,routine])

    useEffect(() => {
        return () => {
          setRoutine({});
          setCrudMode(CRUD_IDLE);
          dispatch(setCurrentRoutine({}));
        }
      }, []);

    return (
        <div className="column-container all-height">
            <div className="header row-container align-center" style={{height:'4em'}}>
                <div>
                    <Typography variant="h5" color="text.black">
                        <Box fontWeight={750}>Perfiles</Box>
                    </Typography>
                </div>

                <div style={{height:'1em'}}>
                    {loading && <LinearProgress/>}
                </div>
            </div>

            <div className="body grow row-container">
                <div className="flex-50 column-container padding">
                    <div className="row-container half-margin" style={{height:'2em'}}>
                        <Button 
                            variant="contained" 
                            style={{marginRight:'1em'}} 
                            color="secondary"
                            disabled={crudMode!==CRUD_IDLE && crudMode!==CRUD_SELECTED}
                            onClick={()=>handleNew()}>Nuevo</Button>
                        <Button 
                            variant="contained" 
                            style={{marginRight:'1em'}} 
                            color="secondary"
                            disabled={crudMode!==CRUD_EDITED && crudMode!==CRUD_NEW}
                            onClick={()=>handleSave()}>Guardar</Button>
                        <div className="grow"></div>
                        <Button 
                            variant="contained" 
                            sx={{marginRight:'1em'}}
                            disabled={crudMode!==CRUD_SELECTED}
                            onClick={()=>setRemoveRoutineDialog(current)}>Eliminar</Button>

                        <Button 
                            variant="contained" 
                            disabled={crudMode!==CRUD_EDITED && crudMode!==CRUD_NEW}
                            onClick={()=>handleCancel()}>Cancelar</Button>
                    </div>

                    <TextField 
                        id="code"
                        name="code"
                        className="half-margin" 
                        placeholder="Codigo" 
                        size="small"
                        value={routine?.code ? routine.code : ''}
                        onChange={handleChange}
                    />

                    <TextField 
                        id="name"
                        name="name"
                        className="half-margin" 
                        placeholder="Nombre" 
                        size="small"
                        value={routine?.name ? routine.name : ''}
                        onChange={handleChange}
                    />

                    <div className='grow column-container' style={{margin:'0.5em'}}>
                        <StudyFinder 
                            studyCodes={current?.studyTypes ? current?.studyTypes : []} 
                            catalog={studies} 
                            add={(study)=>handleAddStudy(study)} 
                            remove={(study)=>setRemoveRoutineStudyDialog(study)}
                            disabled={crudMode===CRUD_EDITED || crudMode===CRUD_NEW}
                        />
                    </div>
                </div>

                <div className="flex-50 column-container padding">

                    <div className="row-container half-margin" style={{height:'2em'}}>
                        <Typography variant="h6" color="text.black">
                            <Box >Lista de perfiles</Box>
                        </Typography>
                    </div>

                    <DataGridPro
                        {...routineData}
                        headerHeight={40}
                        rowHeight={40}
                        disableMultipleSelection
                        disableColumnMenu
                        disableColumnSelector
                        disableMultipleColumnsSorting
                        hideFooter
                        disableSelectionOnClick={crudMode===CRUD_NEW}
                        onCellClick={(params) => handleSelect(params.row)}
                    />

                </div>
            </div>

            {
                
                removeRoutineDialog?.id && 
                <ConfirmationDialog 
                    id="remove_routine_dialog" 
                    open={removeRoutineDialog?.id}
                    message={`Se eliminara el Perfil: ${removeRoutineDialog?.code} ${removeRoutineDialog?.name} `}
                    onCancel={()=>setRemoveRoutineDialog({})}
                    onConfirm={()=>handleRemove(removeRoutineDialog?.id)}
                />
            }

            {
                removeRoutineStudyDialog?.id && 
                <ConfirmationDialog 
                    id="remove_routine_dialog" 
                    open={removeRoutineStudyDialog?.id}
                    message={`Se quitará el Estudio: ${removeRoutineStudyDialog?.code} ${removeRoutineStudyDialog?.name} del Perfil ${current?.code} ${current?.name}`}
                    onCancel={()=>setRemoveRoutineStudyDialog({})}
                    onConfirm={()=>handleRemoveStudy(routine?.id,removeRoutineStudyDialog?.code)}
                />
            }

        </div>
    );
}
 
export default Routine;
import { combineReducers } from 'redux';
import { actionTypes } from '../actions/batches';

const loading = (state = false, action) => {
    switch (action.type) {
        case actionTypes.fetchBatches:
            return true
        case actionTypes.receiveBatches:
            return false
        default:
            return state
    }
}

const data = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.receiveBatches:
            return action.batches
        case actionTypes.clearBatches:
            return []
        default:
            return state
    }
}
export default combineReducers({ loading, data });


import { Info } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import React from "react";

const ConfirmationDialog = ({open,message, onCancel, onConfirm}) => {
    return(
        <Dialog
            open={open}
        >

            <DialogContent>
                <Grid container direction={"column"} justifyContent={"center"} alignItems={"center"}>
                    <Grid item>
                        <Info fontSize="large" color="secondary"/>
                    </Grid>
                    <Grid item>
                        <Typography align="center" sx={{marginTop:'1em'}} variant="h6">{message}</Typography>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={()=>onCancel()}>Cancelar</Button>
                <Button 
                    variant="contained" 
                    sx={{marginRight:'1em'}} 
                    color="secondary"
                    onClick={()=>onConfirm()}>Confirmar</Button>
            </DialogActions>

        </Dialog>


    )
}

export default ConfirmationDialog;
import React, {  useEffect, useState } from "react";
import { AllOut, AttachMoney,    FileUpload, FilterAlt, LocationSearching, Search} from "@mui/icons-material";
import { Box, Button, LinearProgress, MenuItem, Paper, TextField, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { selectCustomersSorted } from "../../state/selectors/catalogs.customers";
import ImportProductionDialog from "./ImportProductionDialog";
import { months } from "../../helpers/months";
import { fetchProduction, fetchProductionByLocator, recalculatePrice, setParams } from "../../state/handlers/production";
import LiquidationDialog from "./LiquidationDialog";
import { filterProduction, selectProduction } from "../../state/selectors/production";
import ProductionStatus from "./ProductionStatus";
import { productionStatus } from "../../helpers/productionStatus";
import { fetchCustomers } from "../../state/handlers/catalogs.customer";

const Production = () => {
    
    const dispatch = useDispatch();

    const [productionDialog,setProductionDialog] = useState(false);
    const [liquidationDialog,setLiquidationDialog] = useState(false);
    
    const [locator,setLocator] = useState('');
    const [filter,setFilter] = useState('');

    const customers = useSelector(selectCustomersSorted);
    const {data, selected, loading, params} = useSelector(filterProduction(filter));

    const production_columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 50,
            sortable: false,
            type: 'number',
        },
        {
            field: 'month',
            headerName: 'Mes',
            sortable: false,
            type: 'string',
            valueGetter:(params)=> params.row.month,
        },
        {
            field: 'year',
            headerName: 'Año',
            sortable: false,
            type: 'string',
            valueGetter:(params)=> params.row.year,
            
        },
        {
            field: 'customer',
            headerName: 'Cliente',
            sortable: false,
            type: 'string',
            renderCell:(params)=> `${params.row.customer.code} ${params.row.customer.name}`,
            flex:2
        },
        {
            field: 'Date',
            headerName: 'Fecha',
            sortable: false,
            type: 'string',
            valueGetter:(params)=> params.row.date,
        },
        {
            field: 'locator',
            headerName: 'Muestra',
            sortable: false,
            type: 'string',
            valueGetter:(params)=> params.row.locator,
            flex:1
        },
        {
            field: 'study_type',
            headerName: 'Estudio',
            sortable: false,
            type: 'string',
            renderCell:(params)=> `${params.row.study_type.code} ${params.row.study_type.name}`,
            flex:2
        },
        {
            field: 'efector',
            headerName: 'Efector',
            sortable: false,
            type: 'string',
            renderCell:(params)=> params.row.efector?.name,
            flex:1
        },
        {
            field: 'value',
            headerName: 'Valor',
            sortable: false,
            type: 'string',
            valueGetter:(params)=> params.row.value,
            flex:1
        },
        {
            field: 'status',
            headerName: 'Estado',
            sortable: false,
            type: 'string',
            renderCell:(params)=> <ProductionStatus status={params.row.status}/> ,
            flex:1
        }    
    ];

    useEffect(() => {
        !customers?.lenght && dispatch(fetchCustomers());
    }, []);
    

    const handleChange = (event) => {
        dispatch(setParams({...params, [event.target.id || event.target.name]:event.target.value}));
    }

    const handleSampleSearch = () => {
        dispatch(fetchProductionByLocator({locator:locator}));
    }

    const handleSearch = () => {
        dispatch(fetchProduction(params));
    }

    const handleRecalculatePrice = () => {
        dispatch(recalculatePrice(params));
    }
    
    const handleNoPriceChange = () => {
        setParams({...params, noPrice: !params.noPrice});
        dispatch(fetchProduction({...params, noPrice: !params.noPrice}));
    }

    const handleFirstPage = () => {
        dispatch(fetchProduction({...params, page:1}));
    }

    const handlePreviousPage = () => {
        dispatch(fetchProduction({...params, page:data.current_page-1}));
    }

    const handleNextPage = () => {
        dispatch(fetchProduction({...params, page:data.current_page+1}));
    }

    const handleLastPage = () => {
        dispatch(fetchProduction({...params, page:data.last_page}));
    }

    const isFirst = data.current_page === 1;
    const isLast = data.current_page === data.last_page;

    return(        
    
    <div className="column-container all-height">
        <div className="header row-container align-center" style={{height:'4em'}}>
            <div>
                <Typography variant="h5" color="text.black">
                    <Box fontWeight={750}>Produccion</Box>
                </Typography>
            </div>
        </div>
        
        <div style={{minHeight:'0.5em'}}>
            {   
                loading &&
                <LinearProgress/>
            }
        </div>

        <div className="body grow column-container margin-top margin-bottom">

                <Paper elevation={3} className="row-container" style={{padding:'2em',marginTop:'1em',marginBottom:'1em'}}>

                    <div className="column-container flex-1" style={{ marginRight:'1em',paddingRight:'3em'}}>
                        <div className="row-container" style={{marginBottom:'0.5em'}}>
                            <TextField 
                                key="month"
                                id="month"
                                name="month"
                                label="Mes" 
                                size="small"
                                fullWidth
                                select
                                value={params?.month || ''}
                                sx={{marginRight:'0.5em'}}
                                onChange={handleChange}
                            >
                                {
                                    months.map( month => 
                                        <MenuItem key={month.value} value={month.value}>
                                            {month.name}
                                        </MenuItem>
                                    )
                                }            
                            </TextField>

                            <TextField 
                                id="year"
                                name="year"
                                key="year"
                                label="Año" 
                                size="small"
                                fullWidh   
                                value={params?.year || ''}
                                onChange={handleChange}              
                            />

                        </div>

                        <TextField 
                            id="customer"
                            name="customer"
                            key="customer"
                            sx={{marginBottom:'0.5em'}} 
                            variant="outlined" 
                            size="small" 
                            label="Cliente"
                            select
                            value={params?.customer || ''}
                            onChange={handleChange}  
                        >
                            <MenuItem key={'all'} value={'all'}>
                                {'Todos'}
                            </MenuItem>

                            {
                                customers.map(customer => (
                                    <MenuItem key={customer.id} value={customer.id}>
                                        {customer.name}
                                    </MenuItem>
                                ))
                            }
                        </TextField>

                        <div className="row-container">
                            <TextField 
                                id="status"
                                name="status"
                                key="status"
                                sx={{marginRight:'0.5em'}} 
                                variant="outlined" 
                                size="small" 
                                label="Estado"
                                fullWidth
                                select
                                value={params?.status || ''}
                                onChange={handleChange}
                            >
                                {
                                    productionStatus.map(status => (
                                        <MenuItem key={status.value} value={status.value}>
                                            {status.name}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>

                            <Button 
                                variant="contained" 
                                sx={{height: '2.8em', marginRight:'1em'}}
                                color="secondary"
                                onClick={handleSearch}
                            >
                                <Search/>
                            </Button>

                            <Button 
                                variant={params?.noPrice ? "contained" : "outlined"}
                                sx={{minWidth:'10em', height: '2.8em'}}
                                startIcon={<FilterAlt/>}
                                color="secondary"
                                onClick={handleNoPriceChange}
                            >
                                SIN PRECIO
                            </Button>
                        </div>
                    </div>

                    <div className="column-container flex-1" style={{ marginRight:'1em',paddingRight:'3em'}}>                        
                        <div className="row-container" style={{marginBottom:'0.5em'}}>
                            <TextField 
                                id="locator"
                                name="locator"
                                key="locator"
                                variant="outlined" 
                                sx={{marginRight:'0.5em'}} 
                                label="Localizador de muestra" 
                                size={"small"} 
                                fullWidth
                                value={locator}
                                onChange={(event)=>setLocator(event.target.value)}
                            >
                            </TextField>
                            <Button
                                    style={{ height: '2.8em' }}
                                    variant="contained"
                                    color="secondary"
                                    disabled={locator===''}
                                    onClick={handleSampleSearch}
                                >
                                    <LocationSearching />
                            </Button>
                        </div>

                        <div className="row-container" style={{marginBottom:'0.5em'}}>
                                <TextField 
                                    id="filter"
                                    name="filter"
                                    key="filter"
                                    variant="outlined" 
                                    label="Filtra por cualquier dato en la pagina actual" 
                                    size={"small"} 
                                    fullWidth
                                    value={filter || ''}
                                    onChange={(event)=>setFilter(event.target.value)}
                                >
                                </TextField>
                        </div>


                        <div className="row-container">
                            <div className="row-container" >
                                <Button disabled={isFirst} variant="outlined" onClick={handleFirstPage}>{"<<"}</Button>
                                <Button disabled={isFirst} variant="outlined" onClick={handlePreviousPage}>{"<"}</Button>
                                <Button disabled={isLast} variant="outlined" onClick={handleNextPage}>{">"}</Button>
                                <Button disabled={isLast} variant="outlined" onClick={handleLastPage}>{">>"}</Button>
                            </div>

                            <div className="row-container grow" style={{justifyContent:'center', alignItems:'center'}} >
                                <Typography sx={{marginRight:'1em'}}>{`Registros totales ${data?.total || '-'}`}</Typography>
                                <Typography>{data?.total > 0 && `Página ${data?.current_page || '-'}  / ${data?.last_page || '-'}`}</Typography>
                            </div>
                        </div>
                    </div>

                    <div className="column-container" style={{marginRight:'1em'}}>
                        <Button 
                            variant="outlined" 
                            startIcon={<AttachMoney/>}
                            sx={{height:'2.8em', marginBottom:'0.5em'}}
                            color="secondary"
                            onClick={handleRecalculatePrice}
                        >
                            Recalcular
                        </Button>
                        {
                            /*
                            <Button 
                                variant="outlined" 
                                startIcon={<Add/>}
                                sx={{height:'2.8em', marginBottom:'0.5em'}}
                                color="secondary"
                            >
                                Nuevo
                            </Button>

                            <Button 
                                variant="outlined" 
                                startIcon={<AttachMoney/>}
                                sx={{height:'2.8em', marginBottom:'0.5em'}}
                                color="secondary"
                            >
                                Editar
                            </Button>

                            <Button 
                                variant="outlined" 
                                startIcon={<Block/>}
                                sx={{height:'2.8em', marginBottom:'0.5em'}}
                                color="secondary"
                            >
                                Anular
                            </Button>
                            */
                        }
                    </div>

                    <div className="column-container">
                        <Button 
                            variant="contained" 
                            startIcon={<FileUpload/>}
                            sx={{height:'2.8em', marginBottom:'0.5em'}}
                            color="secondary"
                            onClick={()=>setProductionDialog(true)}
                        >
                            Importar
                        </Button>

                        <Button 
                            variant="contained" 
                            startIcon={<AttachMoney/>}
                            sx={{height:'2.8em', marginBottom:'0.5em'}}
                            color="secondary"
                            onClick={()=>setLiquidationDialog(true)}
                        >
                            Liquidar
                        </Button>

                    </div>
                </Paper>

                <div className="grow" >
                    <DataGridPro
                        {
                            ...{rows:data?.data || [],columns:production_columns}
                        }
                        headerHeight={40}
                        rowHeight={40}
                        disableMultipleSelection
                        disableColumnMenu
                        disableColumnSelector
                        disableMultipleColumnsSorting
                        hideFooter
                    />
                </div>
        </div>

        {
            productionDialog &&
            <ImportProductionDialog open={productionDialog} onClose={()=>setProductionDialog(false)} initParams={params}/>
        }

        {
            liquidationDialog &&
            <LiquidationDialog open={liquidationDialog} onClose={()=>setLiquidationDialog(false)} initParams={params}/>
        }

    </div>
)};

export default Production;
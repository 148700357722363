import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Add, ArrowBack, ArrowForward,  FilterAlt,  InfoRounded,  Person,   Save, Search  } from "@mui/icons-material";
import {  Button,  InputAdornment, LinearProgress, MenuItem, Skeleton, Tab, Tabs, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { format, parseISO } from 'date-fns';
import _ from "underscore";

import '../styles/Views.css';

import PatientModal from "../components/PatientModal";
import { clearOrder, editOrder } from "../state/actions/order";
import { addStudy, createCustomerOrder, fetchCustomerOrder, fetchOrder, updateCustomerOrder, updateOrder } from "../state/handlers/order";
import { selectCurrentOrder } from "../state/selectors/currentOrder";
import { clearPatient, setPatient } from "../state/actions/patient";
import { selectProfile } from "../state/selectors/profile";
import { selectBatchData } from "../state/selectors/batch";
import { searchStudies, selectCatalogs, selectCompleteRoutines} from "../state/selectors/catalogs";
import { translate } from "../helpers/genders";
import { StudiesGrid } from "./StudiesGrid";
import { ProfilesGrid } from "./ProfilesGrid";
import { OrderStudiesGrid } from "./OrderStudiesGrid";
import envs from "../services/EnvsService";

const Order = () => {

    const {id} = useParams();
    const {loading, order, patient, studies, samples, measurements,status} = useSelector(selectCurrentOrder);
    
    const {allowSelectEfector,requirePatient} = envs;

    const [tabIndex, setTabIndex] = useState(0);
    const [patientDialog, setPatientDialog] = useState(false);
    const [filter, setFilter] = useState('');
    const [efectorFilter, setEfectorFilter] = useState('all');

    const saveRef = useRef();
    
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    
    const profile = useSelector(selectProfile);
    const batch = useSelector(selectBatchData);

    const studiesCatalog = useSelector(searchStudies(filter, efectorFilter));

    const routinesCatalog = useSelector(selectCompleteRoutines());
    const {efectors} = useSelector(selectCatalogs);

    const isCustomer = profile.data?.role.code === 'customer';
    const customerId = profile.data?.customer?.id;

    const protocolRef = useRef(null)


    useEffect(() => {
        if(id)
        {
            if(isCustomer){
                dispatch(fetchCustomerOrder(customerId,id));
            }else{
                dispatch(fetchOrder(id));
            }
        }else{
            dispatch(clearOrder);
            protocolRef.current?.focus();
        }

    }, []);


    useEffect(() => {
        !id && dispatch(clearOrder);
    }, [id]);

    const handleReload = () => {
        if(id)
        {
            if(isCustomer){
                dispatch(fetchCustomerOrder(customerId,id));
            }else{
                dispatch(fetchOrder(id));
            }

        }else{
            dispatch(clearOrder);
        }
    }

    const handleNewOrder = () => {
        id && navigate('/order');
        !id && dispatch(clearOrder);
    }

    const handleClosePatientDialog = (patient) => {
        patient && dispatch(setPatient(patient));
        setPatientDialog(false);
    }

    const handleClearPatient = () => {
        dispatch(clearPatient());
    };

    const handleContinue = () => {
        navigate(`/sample`);
    }

    const handleBack = () => {
        navigate(`/batch/${order.batch_id || batch.id}`);
    }

    const handleFindStudy = (event) => {

        if(canEdit && (event.key === 'Enter' || event.keyCode === 13)) {
            
            const studyType = studiesCatalog.find(s=>s.code === filter);

            if(studyType){
                dispatch(addStudy({study_type_id:studyType.id, study_type:{...studyType},efector_id: studyType.efector.id, efector: studyType.efector}));
                setFilter('');
            }
        }
    }

    const handleSave = () => {

        if(saveRef.current){
            saveRef.current.setAttribute("disabled", "disabled");
        }
        
        !id && !order?.id && dispatch(createCustomerOrder(customerId, {...order, customer_id:customerId, batch_id:batch.id}, studies, samples, measurements));
        
        if (id > 0 || order?.id > 0)
        {
            if (isCustomer){
                dispatch(updateCustomerOrder(customerId, {...order, customer_id:customerId, batch_id:batch.id},studies, samples, measurements));
            }

            if (!isCustomer){
                dispatch(updateOrder({...order},studies, samples, measurements));
            }
       }
    }

    const handleChangeNumber = (value) => {
        dispatch(editOrder({number:value}));
    };

    const handleChangePrority = (value) => {
        dispatch(editOrder({priority:value}));
    };

    const handleChangeObservation = (value) => {
        dispatch(editOrder({observation:value}));
    };

    const havePatient = order.patient_id > 0;

    const canSave = status.editedOrder && (!requirePatient || havePatient) && studies?.length > 0;
    const canEdit = !order.id || !isCustomer || (isCustomer && (order?.status === 'new'));

    return (
        <Fragment>
            <div className="column-container all-height">
                <div className="header row-container" style={{paddingTop:'1em',paddingBottom:'1em', minHeight:'6em'}}>
                    <div className="column-container flex-1" style={{paddingRight:'1em'}}> 
                        
                        <TextField
                            inputRef={protocolRef}
                            label="Protocolo"
                            size={'small'}
                            value={order.number || ''}
                            disabled={!isCustomer || !canEdit}
                            onChange={(event)=>handleChangeNumber(event.target.value)}
                            sx={{marginBottom:'0.5em'}}
                        >
                        </TextField>

                        <div className="grow"></div>
                        <TextField
                            select
                            label="Prioridad"
                            fullWidth
                            size={'small'}
                            value={order?.priority || ''}
                            onChange={(event)=>handleChangePrority(event.target.value)}
                            disabled={!canEdit}
                        >
                            <MenuItem value={'routine'}>Rutina</MenuItem>
                            <MenuItem value={'stat'}>Urgencia</MenuItem>
                        </TextField>

                    </div>

                    <div className="row-container flex-2">
                        <TextField
                            label="Observacion"
                            multiline
                            rows={3}
                            fullWidth
                            value={order.observation || ''}
                            disabled={!isCustomer || !canEdit}
                            onChange={(event)=>handleChangeObservation(event.target.value)}
                        >
                        </TextField>
                    </div>
                    
                    <div className="row-container flex-2" style={{marginLeft:'2em'}}>
                            
                            {_.isEmpty(patient) &&                        
                                <div className="column-container flex-1">
                                    <Skeleton/>
                                    <Skeleton/>
                                    <Skeleton/>
                                    <Skeleton/>
                                    <Skeleton/>
                                </div>
                            }

                            {!_.isEmpty(patient) &&                        
                                <div className="column-container flex-1">


                                        <Box fontWeight="medium">
                                            <Typography variant="h6" color="text.black">{`${patient.first_name} ${patient.last_name}`}</Typography>
                                        </Box>
                                        <Typography color="text.gray" variant="body2">{`Identificacion ${patient.number}`}</Typography>
                                        <Typography color="text.gray" variant="body2">{`Fecha Nac. ${format(parseISO(patient.birth_day), 'dd/MM/yyyy')}`}</Typography>
                                        <Typography color="text.gray" variant="body2">{`Edad ${patient.age}`}</Typography>
                                        <Typography color="text.gray" variant="body2">{translate(patient.gender)}</Typography>
                                        
                                </div>
                            }

                            <div className="column-container" style={{marginLeft:'2em'}}>
                                <Button variant="contained" color="secondary" endIcon={<Person/>} disabled={!isCustomer} onClick={()=>setPatientDialog(true)} sx={{marginBottom:'0.5em'}}>Buscar</Button>
                                <Button variant="contained" color="secondary" endIcon={<Person/>} disabled={!isCustomer || _.isEmpty(patient)} onClick={()=>handleClearPatient()}>Quitar</Button>
                            </div>
                    </div>


                    <div className="grow"></div>

                    <div className="column-container align-right">
                        <Button 
                            color="primary"
                            startIcon={<ArrowBack color="secondary" />}
                            sx={{height:'2.5em', textTransform:'none', paddingLeft:'2em', paddingRight:'2em',  width:'15em', marginBottom:'1em'}}
                            disabled={status.loadingOrder}
                            onClick={()=>handleBack()}>
                                
                                <Box fontWeight="medium">
                                    <Typography>Volver al lote</Typography>
                                </Box>
                                
                        </Button>
                        {
                            isCustomer &&
                            <Button 
                                variant="outlined"
                                startIcon={<Add/>}
                                sx={{ textDecoration:'none', textTransform:"none", width:'15em'}}
                                onClick={()=>handleNewOrder()}
                                disabled={status.loadingOrder || batch.status != 'new'}
                            >
                                
                                <Box fontWeight="regular">
                                    <Typography variant="body2">Orden</Typography>
                                </Box>
                                
                            </Button>
                        }

                        
                    </div>
                </div>
                <div className="progress-container margin-bottom">{status.loadingOrder && <LinearProgress/>}</div>
                <div className="row-container body grow">
                    <div className="column-container" style={{ flexGrow:2,marginRight:'1em'}}>
                        <div className="row-container">
                        
                            <TextField 
                                label="Buscar un estudio" 
                                size="large" 
                                variant="outlined" 
                                fullWidth
                                value={filter || ''}
                                onChange={(event)=>setFilter(event.target.value)}
                                onKeyUp={(event)=>handleFindStudy(event)}
                                InputProps={{
                                    endAdornment:(
                                    <InputAdornment position="end">
                                        <Search/>
                                    </InputAdornment>)}}
                            />

                            {
                                allowSelectEfector &&
                                <TextField 
                                    label="Filtrar por Efector" 
                                    size="large" 
                                    variant="outlined" 
                                    fullWidth
                                    select
                                    value={efectorFilter || ''}
                                    onChange={(event)=>setEfectorFilter(event.target.value)}
                                    sx={{marginLeft:'1em'}}
                                    InputProps={{
                                        endAdornment:(
                                        <InputAdornment position="end">
                                            <FilterAlt/>
                                        </InputAdornment>)}}
                                >
                                    <MenuItem value={'all'}>Todos</MenuItem>
                                    <MenuItem value={'local'}>Locales</MenuItem>
                                    <MenuItem value={'extermal'}>Externos</MenuItem>
                                </TextField>
                            }

                        </div>

                        <Tabs 
                            value={tabIndex} 
                            onChange={(event, newValue)=>setTabIndex(newValue)} 
                            variant="fullWidth" 
                            style={{marginTop:'1em', backgroundColor:'#f4f4f4'}}
                        >
                            <Tab label="Listado completo" sx={tabIndex === 0 ? {textTransform:'none', backgroundColor:'white'}:{textTransform:'none'}}/>
                            <Tab label="Favoritos" sx={tabIndex === 1 ? {textTransform:'none', backgroundColor:'white'}: {textTransform:'none'}}/>
                            <Tab label="Perfiles" sx={tabIndex === 2 ? {textTransform:'none', backgroundColor:'white'}: {textTransform:'none'}}/>
                        </Tabs>

                        <div className="grow">
                            {tabIndex===0 && <StudiesGrid studiesCatalog={studiesCatalog} studies={studies} disabled={!canEdit} efectorFilter={efectorFilter}/>}
                            {tabIndex===1 && <StudiesGrid studiesCatalog={studiesCatalog.filter(study=>study.isFavorite)} studies={studies} disabled={!canEdit}/>}
                            {tabIndex===2 && <ProfilesGrid routines={routinesCatalog} disabled={!canEdit}/>}
                        </div>
                    </div>
                    <div className="column-container" style={{flexGrow:3, paddingTop:'2.8em'}}>
                        <Tabs 
                            value={0} 
                            onChange={(event, newValue)=>setTabIndex(newValue)} 
                            variant="fullWidth"
                            style={{backgroundColor:'#f4f4f4'}}
                        >
                            <Tab label="Estudios agregados" sx={{textTransform:'none'}}/>
                        </Tabs>
                        <div className="grow">
                            <OrderStudiesGrid  studies={studies} samples={samples} efectors={efectors} disabled={!canEdit} />
                        </div>
                    </div>
                </div>

                <div className="row-container justify-right" style={{paddingRight:'2em', marginTop:'0.5em', marginBottom:'0.5em'}}>
                    <Button 
                        color="primary"
                        endIcon={<ArrowForward color="secondary" />}
                        onClick={()=>handleContinue()}
                        disabled={!order.id || canSave}
                        sx={{height:'2.5em', textTransform:'none', paddingLeft:'2em', paddingRight:'2em',  width:'15em'}}>
                            <Box fontWeight="medium">
                                <Typography>Muestras</Typography>
                            </Box>
                    </Button>
                </div>
                <div className="row-container" style={{paddingRight:'2em', marginTop:'0.5em', marginBottom:'0.5em'}}>
                    
                    {
                        
                        !loading && !canEdit && 
                        <div className="row-container" style={{marginLeft:'2em'}}>
                            <InfoRounded/>
                            <Box fontWeight="medium">
                                <Typography variant="body2" style={{marginLeft:'0.5em'}}>La orden ya fue enviada. No se admiten cambios.</Typography>
                            </Box>
                        </div>
                    }

                    <div className="grow"></div>

                    <Button 
                        color="primary"
                        variant="outlined"
                        onClick={()=>handleReload()}
                        sx={{height:'2.5em', textTransform:'none', paddingLeft:'1em', paddingRight:'1em',  width:'10em', marginBottom:'1em'}}>
                            <Box fontWeight="medium">
                                <Typography>Cancelar</Typography>
                            </Box>
                    </Button>
                   
                    <Button 
                        ref={saveRef}
                        style={{marginLeft:'1em'}}
                        variant="contained"
                        color="secondary"
                        endIcon={<Save/>}
                        disabled={!canSave}
                        onClick={()=>handleSave()}
                        sx={{height:'2.5em', textTransform:'none', paddingLeft:'2em', paddingRight:'2em', width:'15em', marginBottom:'1em'}}>
                            
                            <Box fontWeight="medium">
                                <Typography>Guardar</Typography>
                            </Box>
                    </Button>
                </div>
            </div>

            {
                patientDialog &&
                <PatientModal open={patientDialog} onClose={handleClosePatientDialog} initPatient={{...patient}} />
            }

        </Fragment>

    )
}

export default Order;
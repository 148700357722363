import React, { useEffect, useState } from "react";


import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, LinearProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Link, useNavigate, useParams } from "react-router-dom";
import { format, parseISO } from 'date-fns';
import { Add, ArrowBack, ArrowForward, Delete, Visibility } from "@mui/icons-material";
import { DataGridPro } from "@mui/x-data-grid-pro";

import { useDispatch, useSelector } from "react-redux";
import { selectBatch } from "../state/selectors/batch";
import { changeBatchStatus, changeCustomerBatchStatus, fetchBatch, fetchCustomerBatch, updateBatch, updateCustomerBatch } from "../state/handlers/batch";

import { progress, translate } from "../helpers/batchStatus";
import SendToLisDialog from "../components/SendToLisDialog";
import { selectSendableOrders } from "../state/selectors/ordersToSend";
import ScienceIcon from '@mui/icons-material/Science';
import { selectProfile } from "../state/selectors/profile";

import '../styles/Views.css';
import '../styles/Customs.css';
import { removeCustomerOrder } from "../state/handlers/order";
import { translatePriority } from "../helpers/priorities";
import ConfirmationDialog from "../components/ConfirmationDialog";

const RemoveOrderDialog = ({open,onCancel, onConfirm, order}) => {

    return (
        <Dialog open={open} maxWidth="xl">
            <DialogTitle>Eliminar orden</DialogTitle>
            <DialogContent style={{ padding:'2em', minWidth:'40em',maxWidth:'40em'}}>
                <div className="column-container">
                    <Typography variant="body">{`Seguro queres eliminar la orden Id ${order.id}`}</Typography>
                    <Typography variant="body">{order.number && `Protocolo ${order.number}`}</Typography>
                    <Typography variant="body">{order.patient && `Paciente ${order.patient.last_name} ${order.patient.first_name}`}</Typography>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={()=>onCancel([])}>Cancelar</Button>
                <Button variant="contained" color="secondary" onClick={()=>onConfirm()}>Confirmar</Button>
            </DialogActions>
        </Dialog>
    )
}

const Batch = () => {

    const {id} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [remove, tryRemove] = useState(null);
    const [sendDialog, openSendDialog] = useState(false);

    const [cancelDialog, setCancelDialog] = useState(false);
    const {data, loading, creating} = useSelector(selectBatch);
    
    const profile = useSelector(selectProfile);
    const isCustomer = profile.data?.role.code === 'customer';
    const customerId = profile.data?.customer?.id;
    const batch = data;

    useEffect(() => {
        if(isCustomer){
            dispatch(fetchCustomerBatch(customerId,id));
        }else{
            dispatch(fetchBatch(id));
        }
    }, []);

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            sortable: false,
        },
        {
            field: 'number',
            headerName: 'Protocolo',
            sortable: false,
            type: 'string',
            flex: 0.3,
            minWidth: 85
        },
        {
            field: 'patient_number',
            headerName: 'Identificacion',
            sortable: false,
            type: 'string',
            flex: 0.3,
            valueGetter: (params) => `${params.row.patient?.number || ''}`
        },
        {
            field: 'patient',
            headerName: 'Nombres',
            sortable: false,
            type: 'string',
            flex: 0.8,
            valueGetter: (params) => `${params.row.patient?.first_name || ''} ${params.row.patient?.last_name || ''}`
        },
        {
            field: 'priority',
            headerName: 'Prioridad',
            sortable: false,
            type: 'string',
            flex: 0.3,
            minWidth: 85,
            valueGetter: (params) => translatePriority(params.value)
        },
        {
            field: 'date',
            headerName: 'Fecha',
            sortable: false,
            type: 'date',
            flex: 0.3,
            minWidth: 85,
            valueGetter: (params) => format(parseISO(params.value), 'dd/MM/yyyy')
        },
        {
            field: 'status',
            headerName: 'Estado',
            sortable: false,
            flex: 0.5,
            minWidth: 110,
            valueGetter: (params) => translate(params.value)
        },
        {
            field: 'samplesFilled',
            headerName: '',
            sortable: false,
            renderCell: (params) => params.row.samplesFilled == true && <ScienceIcon color="secondary"/>,
        },
        {
            field: 'view_details',
            headerName: '',
            sortable: false,
            renderCell: (params) => <Link to={`/order/${params.row.id}`}><Button disabled={batch?.status === 'canceled'} startIcon={<Visibility sx={{color:'gray'}} />}/></Link>,
        },
        {
            field: 'remove_order',
            headerName: '',
            sortable: false,
            renderCell: (params) => batch.status === "new" && <Button onClick={()=>tryRemove(params.row)} startIcon={<Delete sx={{color:'gray'}} />}/>,
        }
    ];

    const handleReceive = () => {
        if(isCustomer){
            dispatch(changeCustomerBatchStatus(customerId,batch.id,'inProgress'));
        };

        if(!isCustomer){
            dispatch(changeBatchStatus(batch.id,'inProgress'));
        };

    }

    const handleCancel = () => {

        if(isCustomer){
            dispatch(changeCustomerBatchStatus(customerId,batch.id,'cancelled'));
        };

        if(!isCustomer){
            dispatch(changeBatchStatus(batch.id,'cancelled'));
        };

        setCancelDialog(false);
    }

    const handleSend = () => {
        if(isCustomer){
            dispatch(changeCustomerBatchStatus(customerId,batch.id,'delivered'));
        };

        if(!isCustomer){
            dispatch(changeBatchStatus(batch.id,'delivered'));
        };
    };

    const handleRemove = () => {
        dispatch(removeCustomerOrder(customerId,remove.id));
        tryRemove(null);
    }

    const handleCloseDialog = (updateBatchReceived) => {

        if (updateBatchReceived){
            dispatch(updateBatch({...batch, status:'inProgress'}));
        }else{
            dispatch(fetchBatch(id));
        }

        openSendDialog(false);
    }

    const gridData = {rows: batch?.orders || [], columns:columns};
    
    const canSend = batch?.status === 'new' && batch?.orders?.length &&  !batch?.orders?.some(o=>o.isReadyToSend===false);
    const canReceive = batch?.status === 'delivered';
    const canCancel = batch?.status === 'new';

    return (
        <div className="column-container all-height" style={{backgroundColor:'#f4f4f4'}}>
            <div className="header row-container align-center" style={{paddingTop:'1em',paddingBottom:'3em'}}>
                <div className="row-container grow">
                    <div className="column-container justify-center" style={{paddingRight:'1em'}}> 

                        <Box fontWeight={800}>
                            <Typography variant="h6" color="text.black">{`Lote N° ${batch?.id || '--'}`}</Typography>
                        </Box>

                        <Box fontWeight={750}>
                            <Typography color="text.gray" variant="body2">{batch.date}</Typography>
                        </Box>


                    </div>
                    
                    <Divider orientation="vertical" sx={{ height:"3em",marginLeft:'2em',marginRight:'2em'}}/>

                    <div className="column-container justify-center" style={{paddingLeft:'1em', width:'10em'}}>

                        <LinearProgress color="secondary" sx={{Heighth:"1em", marginBottom:'0.5em'}} variant="determinate" value={progress(batch.status)} />
                        
                        <Box fontWeight={600}>
                            <Typography color="text.gray" variant="body2">{translate(batch.status)}</Typography>
                        </Box>
                    </div>

                    <Divider orientation="vertical" sx={{ height:"3em",marginLeft:'2em',marginRight:'2em'}}/>

                    <div className="column-container justify-center" style={{paddingLeft:'1em', width:'10em'}}>
                        <Box fontWeight={600}>
                            <Typography color="text.gray" variant="h6">{batch.customer?.name}</Typography>
                        </Box>
                    </div>
                </div>



                <div className="column-container align-right">
                    <Link to="/dashboard" style={{textDecoration:'none'}}>
                        <Button 
                            color="primary"
                            startIcon={<ArrowBack color="secondary" />}
                            disabled={!canReceive}
                            sx={{height:'2.5em', textTransform:'none', paddingLeft:'2em', paddingRight:'2em',  width:'15em', marginBottom:'1em'}}
                        >
                                
                            <Box fontWeight="medium">
                                <Typography color="black">Mis lotes</Typography>
                            </Box>
                        </Button>
                    </Link>
                    
                    {
                        isCustomer && 
                            <Button 
                                variant="outlined"
                                startIcon={<Add/>}
                                sx={{ textDecoration:'none', textTransform:"none", width:'15em', marginBottom:'0.5em'}}
                                onClick={()=>navigate('/order')}
                                disabled={loading || creating || batch.status != 'new'}
                            >
                                <Box fontWeight="regular">
                                    <Typography variant="body2">Orden</Typography>
                                </Box>
                            </Button>
                    }

                    {
                        !true && 
                            <Button 
                                variant="outlined"
                                startIcon={<Add/>}
                                sx={{ textDecoration:'none', textTransform:"none", width:'15em'}}
                                onClick={()=>navigate('/fast-sample')}
                            >
                                <Box fontWeight="regular">
                                    <Typography variant="body2">Muestra rápida</Typography>
                                </Box>
                            </Button>
                    }
                </div>
            </div>
            <div className="progress-container" >{(loading || creating) && <LinearProgress/>}</div>
            <div className="grow column-container body" style={{backgroundColor:'#f4f4f4'}}>

                <DataGridPro
                    style={{backgroundColor:'white'}}
                    {...gridData}
                    headerHeight={40}
                    rowHeight={40}
                    disableMultipleSelection
                    disableColumnMenu
                    disableColumnSelector
                    disableMultipleColumnsSorting
                    hideFooter
                />

                    <div className="grow row-container justify-right " style={{maxHeight:'6em', paddingTop:'2em'}}>
                        
                            
                        <Button variant="contained"
                                color="secondary"
                                endIcon={<Delete />}
                                sx={{height:'2.5em', textTransform:'none', paddingLeft:'2em', paddingRight:'2em'}}
                                disabled={!canCancel}
                                onClick={()=>setCancelDialog(true)}
                            >
                                <Box fontWeight="medium">
                                    <Typography>Cancelar lote</Typography>
                                </Box>
                        </Button>

                        <div className="grow"></div>

                        {
                            !isCustomer &&
                            
                            <Button variant="contained"
                                color="secondary"
                                endIcon={<ArrowForward />}
                                sx={{height:'2.5em', textTransform:'none', paddingLeft:'2em', paddingRight:'2em'}}
                                disabled={!canReceive}
                                onClick={()=>handleReceive()}
                            >
                                <Box fontWeight="medium">
                                    <Typography>Recibir lote</Typography>
                                </Box>
                            </Button>
                        }

                        {
                            isCustomer &&

                            <Button variant="contained"
                                color="secondary"
                                endIcon={<ArrowForward />}
                                sx={{height:'2.5em', textTransform:'none', paddingLeft:'2em', paddingRight:'2em'}}
                                onClick={()=>handleSend()}
                                disabled={!canSend}
                            >
                                <Box fontWeight="medium">
                                    <Typography>Enviar lote</Typography>
                                </Box>
                            </Button>
                        }

                    </div>
            </div>
            
            {
                sendDialog && <SendToLisDialog open={sendDialog} close={(update)=>handleCloseDialog(update)}/>
            }

            {
                remove && <RemoveOrderDialog open={remove!=null} onCancel={()=>tryRemove(null)} onConfirm={handleRemove} order={remove}/> 
            }

            {
                cancelDialog && 
                    <ConfirmationDialog 
                        open={cancelDialog} 
                        onCancel={()=>setCancelDialog(false)}
                        onConfirm={()=>handleCancel()}
                        message={`Cancelar el lote #${batch?.id} y todas sus ordenes?`}
                    >

                    </ConfirmationDialog>
            }


        </div>
    )
}

export default Batch;
import { actionTypes } from '../actions/batches';
import HttpService from "../../services/HttpService";

const receiveBatches = (batches) => ({ type: actionTypes.receiveBatches, batches });

export const fetchBatches = (statusFilter) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.fetchBatches });

        return HttpService.getInstance().getData(`batches`, 'user-token',{status:statusFilter})
        .then(response => {
            dispatch(receiveBatches(response.data));
        })
    }
}

export const fetchBatchesPage = (statusFilter,page) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchBatches });

        return HttpService.getInstance().getData(`batches`, 'user-token',{status:statusFilter, page:page})
        .then(response => {
            dispatch(receiveBatches(response.data));
        })
    }
}

export const fetchCustomerBatches = (customerId,statusFilter) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.fetchBatches });

        return HttpService.getInstance().getData(`customers/${customerId}/batches`,'user-token',{status:statusFilter})
        .then(response => {
            dispatch(receiveBatches(response.data));
        })
    }
}

export const fetchCustomerBatchesPage = (customerId,statusFilter,page) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchBatches });

        return HttpService.getInstance().getData(`customers/${customerId}/batches`,'user-token',{status:statusFilter, page:page})
        .then(response => {
            dispatch(receiveBatches(response.data));
        })
    }
}

